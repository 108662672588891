import { formatIsoDateToInputValue, removeNullValues } from 'shared/functions/functions';

export const ASSET_SEARCH_FORM_INITIAL_STATE = {
  assetId: '',
  iccode: '',
  typeId: [],
  unapproved: false,
  description: '',
  permissionTypeId: [],
  vendorId: null,
  vendorAssetId: '',
  contentNotes: '',
  projectNotes: '',
  revisionNotes: '',
  credit: '',
  allow: [],
  keyword: '',
};

export const VENDOR_SEARCH_FORM_INITIAL_STATE = {
  name: '',
  vendorId: '',
};

export const INVOICE_SEARCH_FORM_INITIAL_STATE = {
  invoiceId: '',
  vendorInvoiceId: '',
  date: '',
  description: '',
  notes: '',
  vendorId: null,
};

export const CONTRACT_SEARCH_FORM_INITIAL_STATE = {
  contractId: '',
  name: '',
  date: '',
  description: '',
  expirationDate: '',
  vendorId: null,
};

export const ASSET_SUBJECT_FORM_INITIAL_STATE = {
  name: '',
  description: '',
  articleId: '',
};

export const ASSEMBLY_SEARCH_FORM_INITIAL_STATE = {
  assemblyId: '',
  name: '',
  caption: '',
};

export const assetInitialFormData = (assetData = {}) => {
  const { vendor = {}, extendedRights = {}, permissionType = {}, permissionSubtype = {} } = removeNullValues(assetData);

  return {
    iccode: assetData.iccode || '',
    assetTypeId: assetData?.assetType?.assetTypeId || null,
    createDate: assetData.createDate || '',
    permissionTypeId: permissionType.permissionTypeId || null,
    permissionSubtypeId: { value: permissionSubtype?.permissionSubtypeId, label: permissionSubtype?.name },
    expirationDate: assetData.expirationDate || '',
    vendorId: vendor.vendorId,
    vendorAssetId: assetData.vendorAssetId || '',
    inline: assetData.inline || false,
    fileName: assetData?.filenames?.[0]?.filename || '',
    description: assetData.description || '',
    creditXml: assetData.creditXml || '',
    contentNotes: assetData.contentNotes || '',
    projectNotes: assetData.projectNotes || '',
    revisionNotes: assetData.revisionNotes || '',
    rightsNotes: assetData.rightsNotes || '',
    planningId: `${assetData.planningId || ''}`,
    allowDaily: assetData.allowDaily || false,
    allowCore: assetData.allowCore || false,
    allowFeature: assetData.allowFeature || false,
    allowEbi: assetData.allowEbi || false,
    allowMktgadvr: assetData.allowMktgadvr || false,
    allowBce: assetData.allowBce || false,
    allowSynd: assetData.allowSynd || false,
    allowIntl: assetData.allowIntl || false,
    noElectronic: extendedRights.noElectronic || false,
    noInternet: extendedRights.noInternet || false,
    noForeign: extendedRights.noForeign || false,
    noDerived: extendedRights.noDerived || false,
    noNonDerived: extendedRights.noNonDerived || false,
    to15thEd: extendedRights.to15thEd || false,
    to17thEd: extendedRights.to17thEd || false,
    replacedBy: assetData.replacedBy || null,
    replaces: assetData.replaces || null,
  };
};

export const vendorInitialFormData = (vendor) => {
  const { address = { country: {} }, contact = {}, alternateContact = {} } = vendor;

  return {
    name: vendor.name || '',
    addressCity: address.city || '',
    addressCountryId: address?.country?.countryId,
    addressPostalCode: address.postalCode || '',
    addressStateProvince: address.stateProvince || '',
    addressStreet: address.street || '',
    websiteUrl: vendor.websiteUrl || '',
    seeAlsoId: vendor.seeAlsoId || '',
    preferredCredit: vendor.preferredCredit || '',
    specialties: vendor.specialties || '',
    notes: vendor.notes || '',
    contactName: contact.name || '',
    contactEmail: contact.email || '',
    contactPhone: contact.phone || '',
    contactFax: contact.fax || '',
    alternateContactName: alternateContact.name || '',
    alternateContactEmail: alternateContact.email || '',
    alternateContactPhone: alternateContact.phone || '',
    alternateContactFax: alternateContact.fax || '',
  };
};

export const invoiceInitialFormData = (invoice) => {
  const { vendor = {}, contract = {}, attachment = {} } = invoice;

  return {
    vendorId: vendor?.vendorId || null,
    vendorName: vendor?.name || '',
    vendorInvoiceId: invoice?.vendorInvoiceId || '',
    date: formatIsoDateToInputValue(invoice.date) || '',
    amount: invoice.amount,
    contractName: contract.name || '',
    contractId: contract.contractId || '',
    contractDate: formatIsoDateToInputValue(contract.date),
    attachmentFilename: attachment?.filename || '',
    attachmentFile: null,
    description: invoice.description || '',
    notes: invoice.notes || '',
  };
};

export const contractInitialFormData = (contract) => {
  const { languages, platforms, products, territory = {}, attachment = {}, limits, vendor = {} } = contract;

  return {
    vendorId: vendor.vendorId || null,
    contractDate: formatIsoDateToInputValue(contract.date),
    name: contract.name || '',
    description: contract.description || '',
    notes: contract.notes || '',
    attachment: contract.attachment || '',
    territoryId: territory?.territoryId || null,
    derived: contract.derived || false,
    attachmentFilename: attachment?.filename || '',
    languageId: languages && Array.isArray(languages) ? languages.map((l) => l.languageId) : [],
    platformId: platforms && Array.isArray(platforms) ? platforms.map((p) => p.platformId) : [],
    productId: products && Array.isArray(products) ? products.map((p) => p.productId) : [],
    // because the form will be send as a multipart object, we decided to keep the limits object flat
    // ¯\_(ツ)_/¯
    onlineUnitLimit: (limits && limits[0] && String(limits[0].unitLimit || '')) || '',
    onlineDurationId: (limits && limits[0] && limits[0].duration && String(limits[0].duration.durationId || '')) || '',
    onlineNumberOfYears: (limits && limits[0] && String(limits[0].numberOfYears || '')) || '',
    onlineExpirationDate: (limits && limits[0] && limits[0].expirationDate) || '',
    cdDvdUnitLimit: (limits && limits[1] && String(limits[1].unitLimit || '')) || '',
    cdDvdDurationId: (limits && limits[1] && limits[1].duration && String(limits[1].duration.durationId || '')) || '',
    cdDvdNumberOfYears: (limits && limits[1] && String(limits[1].numberOfYears || '')) || '',
    cdDvdExpirationDate: (limits && limits[1] && limits[1].expirationDate) || '',
    printUnitLimit: (limits && limits[2] && String(limits[2].unitLimit || '')) || '',
    printDurationId: (limits && limits[2] && limits[2].duration && String(limits[2].duration.durationId || '')) || '',
    printNumberOfYears: (limits && limits[2] && String(limits[2].numberOfYears || '')) || '',
    printDvdExpirationDate: (limits && limits[2] && limits[2].expirationDate) || '',
  };
};

export const renditionInitialFormData = (rendition = {}) => {
  const { fileFmt = {}, rendType: renditionType = {} } = rendition;

  return {
    renditionTypeId: (renditionType && renditionType.renditionTypeId) || '',
    editUnitId: rendition.editUnitId ? String(rendition.editUnitId) : '',
    notes: rendition.notes || '',
    dirName: rendition.dirName || '',
    fileName: rendition.fileName || '',
    fileSize: rendition.fileSize || '',
    fileFormatId: fileFmt?.fileFmtId || null,
    fileFormatName: fileFmt?.name || '',
    createSys: rendition.createSys || false,
    createThumb: rendition.createThumb || false,
    mimeType: (fileFmt && fileFmt?.mimeType) || '',
    width: rendition.width || '',
    height: rendition.height || '',
    nbrColor: rendition.nbrColor || null,
    resolution: rendition.resolution || '',
    colorSpace: rendition.colorSpace || '',
    audioRes: rendition.audioRes || '',
    audioSampRate: rendition.audioSampRate || '',
    runLength: rendition.runLength || '',
    videoCodecId: rendition.videoCodecId || null,
    videoFps: rendition.videoFps || '',
    videoIsLoop: rendition.videoIsLoop || false,
    offlineType: rendition.offlineType || '',
    offlineCondition: rendition.offlineCondition || '',
    offlineLocation: rendition.offlineLocation || '',
  };
};

export const planningInitialFormData = (planning = {}) => {
  return {
    mediaEditorId: planning.mediaEditor?.userId || null,
    requestedById: planning.requestedBy?.userId || null,
    articleId: planning.articleId || '',
    articleName: planning.articleName || '',
    assetTypeId: planning.assetType?.assetTypeId ?? null,
    clusterName: planning.clusterName || '',
    estimatedCost: planning.estimatedCost || '',
    credit: planning.credit || '',
    description: planning.description || '',
    notes: planning.notes || '',
    rightsNotes: planning.rightsNotes || '',
    dueDate: planning.dueDate || '',
    supportingDoc: planning.supportingDoc || false,
    statusId: planning.status?.statusId ?? null,
    projectId: planning.project?.projectId ?? null,
    disposition: planning.disposition ?? null,
    effortId: planning.effort?.effortId ?? null,
    priorityId: planning.priority?.priorityId ?? null,
    vendorId: planning.vendor?.vendorId ?? null,
    vendorName: planning.vendor?.vendorName || '',
    vendorAssetId: planning.vendorAssetId || '',
    assetId: planning.asset?.assetId || '',
  };
};

export const RENDITION_FILE_DEFAULT_FIELDS = {
  dirName: '',
  fileName: '',
  fileSize: '',
  createSys: false,
  createThumb: false,
  fileFormatName: '',
  fileFormatId: null,
  mimeType: '',
  width: '',
  height: '',
  nbrColor: null,
  runLength: '',
  resolution: '',
  colorSpace: '',
  audioRes: '',
  audioSampRate: '',
  videoCodecId: null,
  videoFps: '',
  videoIsLoop: false,
};

export const PLANNING_SEARCH_FORM_INITIAL_STATE = {
  projectId: null,
  assetTypeIds: null,
  statusIds: null,
  requestedBy: '',
  iccode: '',
  articleName: '',
  planningId: '',
  editorId: null,
  clusterName: '',
  description: '',
  credit: '',
};

export const ARTICLE_SEARCH_FORM_INITIAL_STATE = {
  name: '',
  typeId: null,
  corpusId: '',
  editUnitId: '',
  deleted: false,
  indexed: false,
};

export const USER_LOGIN_INITIAL_FORM_DATA = {
  username: '',
  password: '',
};
